'use client';

import React, { useEffect, useRef, useState } from 'react';
import type { ChangeEvent } from 'react';
import clsx from 'clsx';
import { cva } from 'class-variance-authority';
import { useScreenSize } from '../../hooks/screenSizeHelper';
import type { RadioInputOption, RadioInputProps } from '../../types';
import TinyBorderedCheckmark from '../../icons/icons/Tiny/TinyBorderedCheckmark';

const swatchContainerVariants = cva('grid w-full p-px', {
  variants: {
    size: {
      small:
        'grid-cols-[repeat(auto-fill,_minmax(32px,_1fr))] w-full text-body-2xs font-semibold',
      medium:
        'grid-cols-[repeat(auto-fill,_minmax(44px,_1fr))] w-full text-base',
      large: 'grid-cols-[repeat(auto-fill,_minmax(56px,_1fr))] w-full text-xl',
    },
    isExpanded: {
      true: '',
      false: 'flex-wrap overflow-hidden',
    },
  },
  defaultVariants: {
    size: 'small',
  },
});

const swatchVariants = cva('inline-block cursor-pointer', {
  variants: {
    size: {
      small: 'p-1',
      medium: 'p-1.5',
      large: 'p-2',
    },
  },
  defaultVariants: {
    size: 'small',
  },
});

const inputVariants = cva('m-0 opacity-0 absolute peer', {
  variants: {
    size: {
      small: 'w-6 h-6',
      medium: 'w-8 h-8',
      large: 'w-10 h-10',
    },
  },
  defaultVariants: {
    size: 'small',
  },
});

const labelVariants = cva(
  [
    'cursor-pointer rounded-full uppercase m-0 box-border block text-center relative border-none shadow-[inset_0_0_0_1px_rgba(0,0,0,0.2)]',
    "after:content-[''] after:absolute after:border-2 after:border-gray-400 after:rounded-full after:p-[calc(50%+3px)] after:left-[-5px] after:top-[-5px] after:transition after:duration-200 ease-in-out after:opacity-0",
    'md:hover:after:opacity-100 md:hover:after:border-[rgba(0,0,0,0.2)]',
    'peer-checked:after:opacity-100 peer-checked:after:border-black',
    'peer-disabled:cursor-not-allowed',
    'peer-disabled:after:top-0 peer-disabled:after:h-full peer-disabled:after:rotate-45 peer-disabled:after:left-[calc(50%-1px)] peer-disabled:after:content-none peer-disabled:after:w-0.5',
    'peer-disabled:before:top-0 peer-disabled:before:h-full peer-disabled:before:rotate-45 peer-disabled:before:left-[calc(50%-2px)] peer-disabled:before:bg-white peer-disabled:before:opacity-0.5 peer-disabled:before:w-1 peer-disabled:before:content-none peer-disabled:before:absolute',
  ],
  {
    variants: {
      size: {
        small: 'w-6 h-6 leading-6',
        medium: 'w-8 h-8 leading-8',
        large: 'w-10 h-10 leading-[40px]',
      },
    },
    defaultVariants: {
      size: 'small',
    },
  },
);

const disabledOrCrossoutStyles = [
  "after:absolute after:left-[calc(50%-1px)] after:top-0 after:h-full after:w-0.5 after:rotate-45 after:bg-black after:content-['']",
  "before:absolute before:left-[calc(50%-2px)] before:top-0 before:h-full before:w-1 before:rotate-45 before:bg-white/50 before:content-['']",
];

export interface RadioSwatchesProps extends RadioInputProps {
  showAllOptions?: boolean;
  showToolTips?: boolean;
  options: RadioInputOption[] | undefined;
  isMobile?: boolean;
  collapseByDefault?: boolean;
  defaultSelection?: string[];
  showCheckmark?: boolean;
}

export default function RadioSwatches({
  className,
  name,
  onChange,
  onHover,
  options = [],
  showAllOptions,
  showToolTips,
  size,
  useExternalState,
  allowNone = false,
  defaultSelection = [],
  value,
  collapseByDefault = false,
  showCheckmark = false,
  ...props
}: RadioSwatchesProps): JSX.Element | null {
  const [selectedValue, setSelectedValue] = useState(value);
  const [isExpanded, setIsExpanded] = useState(true);
  const [hasBeenExpandedByUser, setHasBeenExpandedByUser] = useState(false); // used to prevent collapse on first render
  const [hiddenNumber, setHiddenNumber] = useState(0);
  const { isMobileOrSmaller } = useScreenSize();
  const containerRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (showAllOptions) return;
    const handleResize = (): void => {
      if (containerRef.current === null) return;
      const maxVisibleOptions = Math.floor(
        containerRef.current.clientWidth / 46,
      );
      const hiddenOptionCount = options.length - maxVisibleOptions;
      setHiddenNumber(Math.max(hiddenOptionCount, 0));
      if (hiddenOptionCount <= 0) {
        setIsExpanded(true);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [options.length, showAllOptions]);

  useEffect(() => {
    if (showAllOptions || hiddenNumber === 0) return;

    if (hasBeenExpandedByUser) {
      setIsExpanded(true);
    } else if (isMobileOrSmaller || collapseByDefault) {
      setIsExpanded(false);
    } else {
      setIsExpanded(true);
    }
  }, [isMobileOrSmaller, hiddenNumber, collapseByDefault, showAllOptions]);

  // set value when parent updates value
  useEffect(() => {
    if (value) {
      setSelectedValue(value);
    } else if (!allowNone) {
      setSelectedValue(options[0].value);
    }
  }, [value, allowNone, options]);

  if (!options.length) return null;

  const actualValue = useExternalState ? value : selectedValue;

  const changeValue = (newValue: string | number): void => {
    if (allowNone && newValue !== 'wyw' && newValue === actualValue) {
      onChange && onChange('');
      return;
    }

    // Allow the same value to be selected if its wyw (so can edit the wyw options)
    if (!allowNone && newValue !== 'wyw' && newValue === actualValue) {
      return;
    }

    onChange && onChange(newValue);
    setSelectedValue(newValue);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    changeValue(value);
  };

  const preventFutureClickEvents = (
    event: React.MouseEvent<HTMLInputElement>,
  ): void => {
    const targetVal: string = (event.target as HTMLInputElement).value;
    if (
      allowNone &&
      (actualValue === targetVal || defaultSelection.includes(targetVal))
    ) {
      changeValue(targetVal);
    }

    if (!allowNone) {
      if (targetVal === 'wyw') {
        changeValue(targetVal);
      } else {
        event.stopPropagation();
      }
    }
  };

  const handleOnExpand = (): void => {
    setIsExpanded(true);
    setHasBeenExpandedByUser(true);
  };

  // total number of swatches
  const totalSwatches = options.length;
  // highest swatch index that is visible. all higher indices will be hidden and shouldn't be clickable
  const visibleSwatchCutoff = isExpanded
    ? totalSwatches
    : totalSwatches - hiddenNumber - 1;

  return (
    <div className='flex flex-row'>
      <span
        aria-labelledby={name}
        className={clsx(
          swatchContainerVariants({ size, isExpanded }),
          className,
        )}
        role='radiogroup'
        {...props}
        ref={containerRef}
      >
        {options.map(
          ({ crossOut, disabled, label, style, value: optionValue }, index) => {
            const selectedByDefault = optionValue
              ? defaultSelection.includes(optionValue)
              : false;
            const disableOrCrossout = Boolean(disabled || crossOut);
            const checked = selectedByDefault || optionValue === actualValue;
            return (
              <div
                className={clsx(
                  swatchVariants({ size }),
                  !style?.background && (label?.length ?? 0) > 2
                    ? 'text-[0.8em]'
                    : '',
                  !isExpanded && index > visibleSwatchCutoff ? 'hidden' : '',
                )}
                key={optionValue}
                onMouseEnter={() => onHover && onHover(optionValue)}
                onMouseLeave={() => onHover && onHover(undefined)}
              >
                <input
                  aria-label={label}
                  checked={checked}
                  className={clsx(
                    inputVariants({ size }),
                    disableOrCrossout ? [...disabledOrCrossoutStyles] : '',
                  )}
                  disabled={disabled}
                  id={`swatch-option-${name}-${optionValue}-${index}`}
                  name={`swatch-option-${name}-${optionValue}-${index}`}
                  onChange={handleOnChange}
                  onClick={preventFutureClickEvents}
                  ref={React.createRef()}
                  type='radio'
                  value={optionValue}
                />

                <label
                  className={clsx(
                    labelVariants({ size }),
                    !style?.background && !disabled
                      ? 'border-black/20 bg-center'
                      : '',
                  )}
                  data-tooltip={
                    showToolTips && !disabled && label ? label : undefined
                  }
                  htmlFor={`swatch-option-${name}-${optionValue}-${index}`}
                  style={style}
                >
                  {!style?.background && label}
                  {
                    disableOrCrossout && !selectedByDefault ? (
                      <div
                        className={clsx(...disabledOrCrossoutStyles)}
                        data-test-id='radio-swatch-strikethrough'
                      />
                    ) : null /* this div is needed for a cross-out line */
                  }
                  {showCheckmark &&
                  ((!disableOrCrossout && !disabled && checked) ||
                    selectedByDefault) ? (
                    <div className='flex h-full w-full items-center justify-center'>
                      <TinyBorderedCheckmark
                        fill='white'
                        height={16}
                        title='checked'
                        width={16}
                      />
                    </div>
                  ) : null}
                </label>
              </div>
            );
          },
        )}
      </span>
      <button
        className={clsx(
          'cursor-pointer px-[5px] text-blue-600 underline',
          isExpanded ? 'hidden' : '',
        )}
        onClick={handleOnExpand}
        type='button'
      >
        +{hiddenNumber}
      </button>
    </div>
  );
}
