import type { IconProps } from '../../../components/Icon/Icon.util';

export const TinyBorderedCheckmark = ({
  title,
  width = 32,
  height = 32,
  fill = 'none',
  role = 'presentation',
}: IconProps): React.ReactNode => {
  const viewBoxSize = 16;

  return (
    <svg
      fill={fill}
      focusable='false'
      height={height}
      role={role}
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
    >
      {title ? <title>{title}</title> : null}

      <path
        d='M6,14.4h0c-.5,0-1-.2-1.4-.6l-4-4C.2,9.5,0,9,0,8.4s.2-1,.6-1.4l.7-.7c.8-.8,2-.8,2.8,0l1.8,1.8,5.9-5.9c.8-.8,2-.8,2.8,0l.7.7c.4.4.6.9.6,1.4s-.2,1-.6,1.4L7.4,13.8c-.4.4-.9.6-1.4.6ZM2.7,6.7c-.3,0-.5,0-.7.3l-.7.7c-.2.2-.3.4-.3.7s.1.5.3.7l4,4c.2.2.4.3.7.3.3,0,.5,0,.7-.3L14.7,5c.2-.2.3-.4.3-.7s-.1-.5-.3-.7l-.7-.7c-.4-.4-1-.4-1.4,0l-6.6,6.6-2.5-2.5c-.2-.2-.5-.3-.7-.3Z'
        fill='black'
      />
      <path
        d='M2.7,6.7c-.3,0-.5,0-.7.3l-.7.7c-.2.2-.3.4-.3.7s.1.5.3.7l4,4c.2.2.4.3.7.3.3,0,.5,0,.7-.3L14.7,5c.2-.2.3-.4.3-.7s-.1-.5-.3-.7l-.7-.7c-.4-.4-1-.4-1.4,0l-6.6,6.6-2.5-2.5c-.2-.2-.5-.3-.7-.3Z'
        fill='fill'
      />
    </svg>
  );
};

export default TinyBorderedCheckmark;
