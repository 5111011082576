import React from 'react';
import type { ReactNode } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

export type BadgeColor =
  | 'gray'
  | 'pro'
  | 'black'
  | 'transparent'
  | 'blue'
  | 'gradient-gold'
  | 'red';

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  color?: BadgeColor;
  label?: string;
  className?: string;
  children?: ReactNode;
  role?: string;
}

const colorStyles = {
  black: 'bg-black text-white',
  white: 'bg-white text-black',
  pro: 'bg-yellow-300 text-black',
  gray: 'bg-gray-a700 text-white',
  transparent: 'bg-gray-b700 text-black',
  blue: 'bg-blue-600 text-white',
  red: 'bg-red-a400 text-white',
  'gradient-gold': 'bg-gradient-to-br from-yellow-200 to-brown-400 text-white',
};

export default function Badge({
  className,
  color = 'black',
  label,
  children,
  ...props
}: BadgeProps): React.JSX.Element {
  return (
    <div
      className={twMerge(
        clsx(
          'text-body-xs m-[4px] inline-block w-auto whitespace-normal rounded-[2px] p-[4px] font-bold uppercase tracking-[2px]',
          colorStyles[color],
          className,
        ),
      )}
      {...props}
    >
      {children ?? label}
    </div>
  );
}
